import React, {Component} from 'react';
import './Header.css';
import logo from '../../../assets/lif-logo.svg';
import {Col, Dropdown, Row} from "react-bootstrap";
import 'antd/dist/antd.css';
import history from "../history";
import {ArrowDropDownOutlined} from "@material-ui/icons";
import {slide as Menu} from 'react-burger-menu';
import {isMobile} from 'react-device-detect';


const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <div
        className='menu-link'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <ArrowDropDownOutlined/>
    </div>
));


const DropdownMenu = () => (
    <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
            Our Solution
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item onClick={() => window.parent.location.href ='https://lif.id/corporatewellnessprogram'}>Corporate Wellness Program</Dropdown.Item>
            <Dropdown.Item onClick={() => window.parent.location.href ='https://lif.id/lifestyle'}>Lifestyle Assesment</Dropdown.Item>
            <Dropdown.Item onClick={() => window.parent.location.href ='https://lif.id/csr'}>CSR Wellness Event</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

class Burger extends Component {
    constructor() {
        super();
        this.state = {
            areMenusOpen: false,
        }
        this.closeBurgerMenu = this.closeBurgerMenu.bind(this);
        this.redirectPage = this.redirectPage.bind(this);
        this.parentLocation = this.parentLocation.bind(this);
    }

    redirectPage(url){
        history.push(url);
        this.closeBurgerMenu();
    }

    closeBurgerMenu() {
        this.setState({areMenusOpen: false});
    }

    parentLocation = (url) => {
        window.parent.location.href = url;
    };

    render() {
        return (
            <Menu onOpen={() => this.setState({areMenusOpen: true})}
                  onClose={() => this.setState({areMenusOpen: false})} isOpen={this.state.areMenusOpen} right>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                        Our Solution
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => window.parent.location.href ='https://lif.id/corporatewellnessprogram'}>Corporate Wellness Program</Dropdown.Item>
                        <Dropdown.Item onClick={() => window.parent.location.href ='https://lif.id/lifestyle'}>Lifestyle Assesment</Dropdown.Item>
                        <Dropdown.Item onClick={() => window.parent.location.href ='https://lif.id/csr'}>CSR Wellness Event</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='menu-link mt-3' onClick={() => window.parent.location.href ='https://lif.id/aboutlif'}>About</div>
                <div className='menu-link mt-3' onClick={() => window.parent.location.href ='https://lif.id/blogs'}>Healthy Living</div>
                <div className='menu-link mt-3' onClick={() => window.parent.location.href ='https://lif.id/contactus'}>Contact</div>
            </Menu>
        )
    }
}

const Header = () => (
    <div className='Header'>
        <div className='display-on-mobile'>
            {isMobile && <Burger/>}
        </div>
        <Col>
            <Row style={{width: '50%'}}>
                <Col>
                    <img onClick={() => window.parent.location.href ='https://lif.id'} style={{cursor: 'pointer'}} src={logo}/>
                </Col>
                <Col md={10} style={{margin: 'auto'}}>
                    {!isMobile && <Row className='column-container justify-content-between'>
                        <div className="menu-link">
                            <DropdownMenu/>
                        </div>
                        <div className='menu-link'>
                            <div onClick={() => window.parent.location.href ='/aboutlif'}>About</div>
                        </div>
                        <div className='menu-link'>
                            <div onClick={() => window.parent.location.href ='/blogs'}>Healthy Living</div>
                        </div>
                        <div className='menu-link'>
                            <div onClick={() => window.parent.location.href ='/contactus'}>Contact</div>
                        </div>
                    </Row>}
                </Col>
            </Row>
        </Col>
    </div>
);

export default Header;
