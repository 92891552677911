import React from "react";
import './CWPDownload.css';
import {Col, Form, Row} from "react-bootstrap";
import BulletList from "../../../CommonComponent/BulletList/BulletList";
import bookImg from '../../../../assets/lead-magnet.png';
import GeneralButton from "../../../CommonComponent/GeneralButton/GeneralButton";
import {validateEmail, validatePhone} from "../../../ContactPage/ContactPage";
import history from "../../../CommonComponent/history";
import axiosInstance from "../../../CommonComponent/AxiosInstane";
import PhoneInput from 'react-phone-input-2'


class CWPDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            emailAddress: '',
            contactNumber: 0,
            jobTitle: '',
            company: '',
            numberOfEmployee: 0,
            errors: {}
        }
    }

    onSubmit() {
        if (this.handleValidation()) {
            const request = {
                WebsiteVisitor: {
                    Name: this.state.name,
                    Email: this.state.emailAddress,
                    ContactNumber: this.state.contactNumber,
                    JobTitle: this.state.jobTitle,
                    Company: this.state.company,
                    EmployeeNo: this.state.numberOfEmployee
                }
            }
            console.log(request);

            axiosInstance.post('/visitor', request).then(function (response) {
                    console.log(response.data);
                    if(response.data.success){
                        console.log('thank-you')
                    }
                }
            )
            history.push('/thankyou');
        }
    }

    setNumberOfEmployee(option){
        switch (option) {
            case 'Please Select' :
                this.setState({numberOfEmployee: 0});
                break;
            case '0-50' :
                this.setState({numberOfEmployee: 25});
                break;
            case '50-200':
                this.setState({numberOfEmployee: 125});
                break;
            case '200-500':
                this.setState({numberOfEmployee: 350});
                break;
            case '500-1000':
                this.setState({numberOfEmployee: 750});
                break;
            case '>1000':
                this.setState({numberOfEmployee: 1500});
                break;
        }
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        //Name
        if (this.state.name === '') {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Email
        if (this.state.emailAddress === '') {
            formIsValid = false;
            errors["emailAddress"] = "Cannot be empty";
        } else if (typeof this.state.emailAddress !== "undefined") {

            if (!validateEmail(this.state.emailAddress)) {
                formIsValid = false;
                errors["emailAddress"] = "Email is not valid";
            }
            var restrictDomain = ['gmail','yahoo','ymail','google'];
            if (new RegExp(restrictDomain.join("|")).test(this.state.emailAddress.toLowerCase())) {
                formIsValid = false;
                errors["emailAddress"] = "Please enter your business email address.";
            }
        }

        if (!validatePhone(this.state.contactNumber)) {
            formIsValid = false;
            errors["contactNumber"] = "Please input a valid contact number";
        }

        if (!this.state.jobTitle) {
            formIsValid = false;
            errors["jobTitle"] = "Cannot be empty";
        }
        if (!this.state.company) {
            formIsValid = false;
            errors["company"] = "Cannot be empty";
        }
        if (this.state.numberOfEmployee < 1) {
            formIsValid = false;
            errors["numberOfEmployee"] = "Cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    render() {
        return (<Col md={10} sm={12} xs={12} className='auto-margin card-spacer'>
            <Row>
                <Col md={6} sm={11} xs={11} className='auto-margin'>
                    <div className='page-title label-align red-font-color'>
                        You will get to learn:
                    </div>
                    <Row className='content-spacer'>
                        <BulletList
                            datas={['What is a Corporate Wellness Program? ', 'Why is Corporate Wellness Program Important?', 'How to implement Corporate Wellness Program?', 'How to encourage workplace wellness?']}/>
                    </Row>
                    <div className='circle-bg auto-margin content-spacer'>
                        <img className='img-fluid auto-margin' src={bookImg}/>
                    </div>
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <div className='form-container card-spacer'>
                        <Form>
                            <Form.Group>
                                <div className='content-label center-label mb-3'>Name</div>
                                <Form.Control className='rounded-corner general-form' value={this.state.name} onChange={e => this.setState({name: e.target.value})} type="text"/>
                                <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                            </Form.Group>
                            <Form.Group className='content-spacer'>
                                <div className='content-label center-label mb-3'>Contact Number</div>
                                <PhoneInput country={'id'} enableSearch={true} inputClass={'rounded-corner general-form'} inputStyle={{width: '100%'}} value={this.state.contactNumber} onChange={phone => this.setState({ contactNumber: phone })}/>
                                <span style={{color: "red"}}>{this.state.errors["contactNumber"]}</span>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail" className='content-spacer'>
                                <div className='content-label center-label mb-3'>Work Email</div>
                                <Form.Control className='rounded-corner general-form' value={this.state.emailAddress} onChange={e => this.setState({emailAddress: e.target.value})} type="email"/>
                                <span style={{color: "red"}}>{this.state.errors["emailAddress"]}</span>
                            </Form.Group>
                            <Form.Group className='content-spacer'>
                                <div className='content-label center-label mb-3'>Job Title</div>
                                <Form.Control className='rounded-corner general-form' value={this.state.jobTitle} onChange={e => this.setState({jobTitle: e.target.value})} type="text"/>
                                <span style={{color: "red"}}>{this.state.errors["jobTitle"]}</span>
                            </Form.Group>
                            <Form.Group className='content-spacer'>
                                <div className='content-label center-label mb-3'>Company</div>
                                <Form.Control className='rounded-corner general-form' value={this.state.company} onChange={e => this.setState({company: e.target.value})} type="text"/>
                                <span style={{color: "red"}}>{this.state.errors["company"]}</span>
                            </Form.Group>
                            <Form.Group className='content-spacer'>
                                <div className='content-label center-label mb-3'>Number of Employees</div>
                                <Form.Control className='rounded-corner general-form' onChange={e => this.setNumberOfEmployee(e.target.value)} as="select">
                                    <option>Please Select</option>
                                    <option>0-50</option>
                                    <option>50-200</option>
                                    <option>200-500</option>
                                    <option>500-1000</option>
                                    <option>>1000</option>
                                </Form.Control>
                                <span style={{color: "red"}}>{this.state.errors["numberOfEmployee"]}</span>
                            </Form.Group>
                            <div className='column-container'>
                                <div className='auto-margin'>
                                    <GeneralButton onClickAction={()=> this.onSubmit()} label={'Download'}/>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Col>);
    }
}

export default CWPDownload;
