import React, {useState} from 'react';
import ModalVideo from 'react-modal-video';
import '../../../../../node_modules/react-modal-video/scss/modal-video.scss';
import PropTypes from 'prop-types';
import './UpperContent.css';
import '../../../../App.css'
import {Col, Row} from "react-bootstrap";
import {Divider} from "@material-ui/core";
import ContactUs from "../../../CommonComponent/ContactUs/ContactUs";
import GeneralButton from "../../../CommonComponent/GeneralButton/GeneralButton";
import PlayButtonIcon from '../../../../assets/play-button.svg';
import homeBgimg from '../../../../assets/homepage-title-bg.png'

class UpperContent extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({isOpen: true})
    }

    render() {
        return (
            <Col md={12}>
                <Row className='column-container'>
                    <Col md={{span: 5, offset: 1}} sm={12} xs={12} className='align-self-center title-margin solution-title grey-font-color title-line-height'>
                        Build Communities of <div className='large-page-title red-font-color'>Healthy and
                        Happy </div>Individuals in Your Workplace
                        <div>- with <span className='red-font-color'>LIF</span></div>
                        <div className='content-label grey-font-color mt-3'>Tech-based wellness solution</div>
                        <Row>
                            <Col md={10} sm={10} xs={10} className='ml-2 mt-4'>
                                <ModalVideo channel='youtube' autoplay={0} isOpen={this.state.isOpen} videoId="uLkP21lRkeY"
                                            onClose={() => this.setState({isOpen: false})}/>
                                <Row>
                                    <ContactUs></ContactUs>
                                    <div className='ml-md-4'>
                                        <GeneralButton icon={PlayButtonIcon} color={'transparent'}
                                                       label={'Watch Our Video'}
                                                       labelColor={'#DE1E30'}
                                                       onClickAction={() => this.openModal()}></GeneralButton>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} sm={12} xs={12}>
                        <img className='img-fluid' src={homeBgimg}/>
                    </Col>
                </Row>
            </Col>
        )
    }
}

UpperContent.propTypes = {};

UpperContent.defaultProps = {};

export default UpperContent;
